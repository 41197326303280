function GoldIcon() {
  return (
    <svg
    className="icon"
     width='24'
     height='24'
     vertical-align='bottom'
    //  style={{vertical_align: 'bottom'}}
    viewBox="0 0 1024 1024"
      ersion="1.1"
    
      
    >
      <path
        d="M959.849 634.644c-1.641-76.786-201.052-97.358-319.152-97.358s-317.509 20.572-319.152 97.358h-0.151v99.162c0 77.374 200.715 98.006 319.303 98.006 118.59 0 319.303-20.75 319.303-98.464v-98.703h-0.151z"
        fill="#FCB814"
      />
      <path
        d="M959.849 634.644c-1.641-76.786-201.052-97.358-319.152-97.358s-317.509 20.572-319.152 97.358h-0.151v99.162c0 77.374 200.715 98.006 319.303 98.006 118.59 0 319.303-20.75 319.303-98.464v-98.703h-0.151z"
        fill=""
      />
      <path
        d="M640.697 733.78c-118.588 0-319.303-20.675-319.303-98.222 0-77.571 200.715-98.272 319.303-98.272S960 557.987 960 635.558c0 77.547-200.715 98.222-319.303 98.222z"
        fill="#FCB814"
      />
      <path
        d="M837.826 455.421c-1.641-76.786-201.052-97.356-319.152-97.356s-317.51 20.57-319.152 97.356h-0.151v99.162c0 77.376 200.714 98.006 319.303 98.006 118.588 0 319.303-20.75 319.303-98.463v-98.705h-0.151z"
        fill="#FCB814"
      />
      <path
        d="M837.826 455.421c-1.641-76.786-201.052-97.356-319.152-97.356s-317.51 20.57-319.152 97.356h-0.151v99.162c0 77.376 200.714 98.006 319.303 98.006 118.588 0 319.303-20.75 319.303-98.463v-98.705h-0.151z"
        fill=""
      />
      <path
        d="M518.674 554.559c-118.59 0-319.303-20.677-319.303-98.223 0-77.571 200.713-98.272 319.303-98.272 118.588 0 319.303 20.701 319.303 98.272 0 77.547-200.715 98.223-319.303 98.223z"
        fill="#FCB814"
      />
      <path
        d="M702.457 289.547c-1.642-76.786-201.052-97.358-319.152-97.358S65.794 212.761 64.153 289.547h-0.15v99.162c0 77.374 200.713 98.006 319.303 98.006 118.589 0 319.303-20.75 319.303-98.464v-98.703h-0.152z"
        fill="#FCB814"
      />
      <path
        d="M702.457 289.547c-1.642-76.786-201.052-97.358-319.152-97.358S65.794 212.761 64.153 289.547h-0.15v99.162c0 77.374 200.713 98.006 319.303 98.006 118.589 0 319.303-20.75 319.303-98.464v-98.703h-0.152z"
        fill=""
      />
      <path
        d="M383.303 388.683C264.715 388.683 64 368.007 64 290.46c0-77.571 200.715-98.272 319.303-98.272 118.59 0 319.304 20.701 319.304 98.272 0.001 77.547-200.714 98.223-319.304 98.223z"
        fill="#FCB814"
      />
      <path
        d="M143.75 327.758c-8.246 0-14.933-6.687-14.933-14.933 0-84.948 213.581-89.25 256.398-89.25 8.246 0 14.933 6.686 14.933 14.933 0 8.246-6.687 14.933-14.933 14.933-111.352 0-226.531 22.21-226.531 59.384-0.001 8.246-6.686 14.933-14.934 14.933z"
        fill="#EEEEEE"
      />
    </svg>
  );
}

export default GoldIcon;
